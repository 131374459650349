
import { Vue, Component } from "vue-property-decorator";
import { Article } from "@planetadeleste/vue-mc-goodnews";

@Component
export default class InstructorArticle extends Vue {
  obArticle: Article = new Article();

  mounted(): void {
    this.$nextTick(this.loadArticle);
  }

  async loadArticle(): Promise<void> {
    this.obArticle = new Article({ slug: "expositor" });
    await this.obArticle.fetch();

    this.$emit("loaded", this.obArticle);
  }
}
